// @flow

import { EventPublisher } from "@deepintent/react-component-lib-core";
import LoggerManager from "./LoggerManager";

class EventPublisherManager {
	init = () => {
		if (window.eventPublisher) {
			LoggerManager.getContext().error("EVENT_PUBLISHER_ALREADY_INIT", {
				message:
					"EventPublisher can only be initialized once. Please remove calls initializing " +
					"the eventPublisher more than once.",
				location: "EventPublisherManager::init",
			});
		} else {
			window.eventPublisher = new EventPublisher();
		}
	};

	static getContext(): EventPublisher {
		return window.eventPublisher;
	}
}

export default EventPublisherManager;
