// @flow

import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { isNumber } from "@deepintent/react-component-lib-core";
import Loadable from "react-loadable";
import { ApplicationEnum } from "@deepintent/react-component-internal";
import App from "./containers/App";
import Constant from "./utils/Constants";
import Header from "./components/Header/Header";
import MainContainer from "./containers/MainContainer";
import HomeContainer from "./containers/HomeContainer/HomeContainer";
import AsyncLoader from "./containers/AsyncLoader/AsyncLoader";
import ProtectedRoute from "./containers/ProtectedRoute";
import FeatureAuthorisationFlag from "./enums/FeatureAuthorisationFlag";
import redirectToFirstAuthorized from "./redirectToFirstAuthorized";

const { lazy, Suspense } = React;
const APPNAME = ApplicationEnum?.MMP?.name;
const {
	ID_TO_PATH_MAPPING,
	DEALS: { EDIT_TAG, MAP_TAG, SENT_ROUTE, RECEIVED_ROUTE, GET_PIXEL },
	TEMP_REDIRECT_URL,
} = Constant;

const MainContainerWithRouter = withRouter(MainContainer);

const AdTagsContainer = Loadable({
	loader: () => import(/* webpackChunkName: "AdTagsList" */ "./containers/Integrations/AdTag/AdTagsList/AdTagsList"),
	loading: AsyncLoader,
});

const CreateAdTagContainer = Loadable({
	loader: () =>
		import(
			/* webpackChunkName: "CreateAdTagWrapper" */ "./containers/Integrations/AdTag/Create/CreateAdTagWrapper"
		),
	loading: AsyncLoader,
});

const CreateInventorySlider = Loadable({
	loader: () =>
		import(
			/* webpackChunkName: "CreateInventory" */ "./containers/Integrations/Inventory/CreateInventory/CreateInventoryWrapper"
		),
	loading: AsyncLoader,
});

const CreateHealthCareIdentitySlider = Loadable({
	loader: () =>
		import(
			/* webpackChunkName: "CreateHealthCareIdentity" */ "./containers/Integrations/Inventory/CreateHealthcareIdentity/CreateHealthCareIdentityWrapper"
		),
	loading: AsyncLoader,
});
const EditAdTagSlider = Loadable({
	loader: () => import(/* webpackChunkName: "EditAdTagSlider" */ "./containers/Deals/AdTag/AdTagWrapper"),
	loading: AsyncLoader,
});
const GetAdTagContainer = Loadable({
	loader: () => import(/* webpackChunkName: "GetAdTag" */ "./containers/Integrations/AdTag/GetAdTag/GetAdTag"),
	loading: AsyncLoader,
});
const InventoryContainer = Loadable({
	loader: () => import(/* webpackChunkName: "Inventory" */ "./containers/Integrations/Inventory/Inventory"),
	loading: AsyncLoader,
});
const OpenAuctionContainer = Loadable({
	loader: () => import(/* webpackChunkName: "OpenAuction" */ "./containers/OpenAuction/OpenAuction"),
	loading: AsyncLoader,
});

const ViewCategoriesContainer = Loadable({
	loader: () =>
		import(/* webpackChunkName: "InventoryViewCategories" */ "./containers/Integrations/Inventory/ViewCategories"),
	loading: AsyncLoader,
});

const UploadListContainer = Loadable({
	loader: () =>
		import(/* webpackChunkName: "InventoryUploadListContainer" */ "./containers/Integrations/Inventory/UploadList"),
	loading: AsyncLoader,
});
const EditAdTagInventoryMappingContainer = Loadable({
	loader: () =>
		import(
			/* webpackChunkName: "EditAdTagInventoryMappingContainer" */ "./containers/Integrations/AdTag/AdTagsList/InlineActions/InventoryMapping"
		),
	loading: AsyncLoader,
});
const DashboardContainer = Loadable({
	loader: () => import("./containers/Dashboard/DashboardContainer"),
	loading: AsyncLoader,
});
const AudienceContainer = Loadable({
	loader: () => import(/* webpackChunkName: "AudienceContainer" */ "./containers/Audience/Audience"),
	loading: AsyncLoader,
});
const CreateAudienceContainer = Loadable({
	loader: () =>
		import(
			/* webpackChunkName: "CreateAudienceContainer" */ "./containers/Audience/CreateAudience/CreateAudienceWrapper"
		),
	loading: AsyncLoader,
});
const GetTagContainer = Loadable({
	loader: () => import(/* webpackChunkName: "GetAudienceTagContainer" */ "./containers/Audience/GetTag/GetTag"),
	loading: AsyncLoader,
});

const DealsContainer = Loadable({
	loader: () => import(/* webpackChunkName: "DealsContainer" */ "./containers/Deals/Deals"),
	loading: AsyncLoader,
});
const CreateDealContainer = Loadable({
	loader: () =>
		import(/* webpackChunkName: "CreateDealContainer" */ "./containers/Deals/CreateDeal/CreateDealWrapper"),
	loading: AsyncLoader,
});
const CreativeLibraryContainer = Loadable({
	loader: () =>
		import(/* webpackChunkName: "CreativeLibraryContainer" */ "./containers/CreativeLibrary/CreativeLibrary"),
	loading: AsyncLoader,
});
const CreativeApprovalContainer = Loadable({
	loader: () =>
		import(
			/* webpackChunkName: "CreativeApprovalContainer" */ "./containers/CreativeLibrary/CreativeApproval/CreativeApprovalContainer"
		),
	loading: AsyncLoader,
});
const CreativeManagePolicyContainer = Loadable({
	loader: () =>
		import(
			/* webpackChunkName: "CreativeManagePolicyContainer" */ "./containers/CreativeLibrary/ManagePolicy/CreativeManagePolicyContainer"
		),
	loading: AsyncLoader,
});
const GetPixelSlider = Loadable({
	loader: () => import(/* webpackChunkName: "GetPixelSlider" */ "./containers/Deals/GetPixel/GetPixel"),
	loading: AsyncLoader,
});

const NotAuthorized = lazy(() =>
	import(/* webpackChunkName: "NotAuthorized" */ "./components/shared/UnauthorizedPage/UnauthorizedPage")
);

const PublishRx = Loadable({
	loader: () => import(/* webpackChunkName: "PublishRx" */ "./components/Settings/PublishRxWrapper"),
	loading: AsyncLoader,
});

const Outcomes = Loadable({
	loader: () => import(/* webpackChunkName: "Outcomes" */ "./components/Outcomes/OutcomesWrapper"),
	loading: AsyncLoader,
});

const RegistrationReportContainer = Loadable({
	loader: () =>
		import(
			/* webpackChunkName: "RegistrationReportContainer" */ "./components/Outcomes/RegistrationReport/RegistrationReportWrapper"
		),
	loading: AsyncLoader,
});

export default (
	<App>
		<React.Fragment>
			<Suspense fallback={<div />}>
				<Switch>
					<Route
						exact
						path={ID_TO_PATH_MAPPING.notAuthorized}
						render={(props) => <NotAuthorized {...props} />}
					/>
					<Route
						path="/"
						render={() => (
							<React.Fragment>
								<Header application={APPNAME} />
								<MainContainerWithRouter>
									<Suspense fallback={<div />}>
										<Switch>
											{/* Added temp route which will be used for reloading current page
					    after switching organisation */}
											<Route exact path={TEMP_REDIRECT_URL} component={null} />
											<ProtectedRoute
												exact
												path="/deals/create"
												render={(renderProps) => (
													<CreateDealContainer key="create-deal" {...renderProps} />
												)}
												authorisationField={FeatureAuthorisationFlag.DEALS.value}
											/>
											<ProtectedRoute
												exact
												path="/deals/edit/:id"
												render={(renderProps) => {
													const {
														match: {
															params: { id },
														},
													} = renderProps;
													if (!id || !isNumber(id)) {
														return <Redirect to="/deals" />;
													}
													return (
														<CreateDealContainer
															dealId={id}
															{...renderProps}
															key="edit-deal"
														/>
													);
												}}
												authorisationField={FeatureAuthorisationFlag.DEALS.value}
											/>
											<Route
												path="/"
												render={() => {
													return (
														<HomeContainer application={APPNAME}>
															<Switch>
																<ProtectedRoute
																	path={ID_TO_PATH_MAPPING.outcomes}
																	render={(renderProps) => {
																		const { match } = renderProps;
																		const { path } = match;

																		return (
																			<Switch>
																				<Route
																					exact
																					path={[
																						`${path}/create`,
																						`${path}/:id`,
																					]}
																					render={(props) => {
																						return (
																							<RegistrationReportContainer
																								{...props}
																								key="registration-report"
																							/>
																						);
																					}}
																				/>
																				<Route
																					exact
																					path={path}
																					render={(props) => (
																						<Outcomes {...props} />
																					)}
																				/>
																			</Switch>
																		);
																	}}
																	authorisationField={
																		FeatureAuthorisationFlag.PUBLISH_RX.value
																	}
																/>
																<ProtectedRoute
																	exact
																	path={ID_TO_PATH_MAPPING.publishRx}
																	authorisationField={
																		FeatureAuthorisationFlag.PUBLISH_RX.value
																	}
																	component={PublishRx}
																/>
																<ProtectedRoute
																	exact
																	path="/dashboard"
																	component={DashboardContainer}
																	authorisationField={
																		FeatureAuthorisationFlag.DASHBOARD.value
																	}
																/>
																<ProtectedRoute
																	path={Constant.ID_TO_PATH_MAPPING.creativeLibrary}
																	render={(renderProps) => {
																		const { match } = renderProps;
																		const { path } = match;
																		return (
																			<Switch>
																				<Route
																					exact
																					path={`${path}/managepolicies`}
																					render={(props) => {
																						return (
																							<CreativeManagePolicyContainer
																								{...props}
																								key="manage-policies"
																							/>
																						);
																					}}
																				/>
																				<Route
																					path={`${path}/:creativeId`}
																					render={(props) => {
																						const {
																							match: {
																								params: { creativeId },
																							},
																						} = props;
																						return (
																							<CreativeApprovalContainer
																								{...props}
																								key="edit-lib"
																							/>
																						);
																					}}
																				/>
																				<Route
																					exact
																					path={`${path}/`}
																					render={(props) => (
																						<CreativeLibraryContainer
																							{...props}
																						/>
																					)}
																				/>
																			</Switch>
																		);
																	}}
																	authorisationField={
																		FeatureAuthorisationFlag.CREATIVE_LIBRARY.value
																	}
																/>
																<ProtectedRoute
																	path={Constant.ID_TO_PATH_MAPPING.audience}
																	render={(audienceProps) => {
																		const { match } = audienceProps;
																		const { path } = match;
																		return (
																			<AudienceContainer {...audienceProps}>
																				{({ refetch: refetchAudienceList }) => (
																					<Switch>
																						<Route
																							exact
																							path={`${path}/create`}
																							render={(props) => (
																								<CreateAudienceContainer
																									{...props}
																									refetchAudienceList={
																										refetchAudienceList
																									}
																								/>
																							)}
																						/>
																						<Route
																							exact
																							path={`${path}/:id`}
																							render={(props) => (
																								<CreateAudienceContainer
																									{...props}
																									refetchAudienceList={
																										refetchAudienceList
																									}
																								/>
																							)}
																						/>
																						<Route
																							exact
																							path={`${path}/gettag/:id/`}
																							render={(props) => (
																								<GetTagContainer
																									{...props}
																								/>
																							)}
																						/>
																					</Switch>
																				)}
																			</AudienceContainer>
																		);
																	}}
																	authorisationField={
																		FeatureAuthorisationFlag.AUDIENCE.value
																	}
																/>
																<ProtectedRoute
																	exact
																	path="/adtag/create"
																	component={CreateAdTagContainer}
																	authorisationField={
																		FeatureAuthorisationFlag.AD_TAG.value
																	}
																/>
																<ProtectedRoute
																	exact
																	path="/adtag/get/:id"
																	component={GetAdTagContainer}
																	authorisationField={
																		FeatureAuthorisationFlag.AD_TAG.value
																	}
																/>
																<ProtectedRoute
																	path="/adtag"
																	render={(adTagListProps) => {
																		const { match } = adTagListProps;
																		const { path } = match;
																		return (
																			<Switch>
																				<Route
																					exact
																					path={`${path}/:id/inventories`}
																					render={(props) => (
																						<AdTagsContainer
																							{...adTagListProps}
																						>
																							<EditAdTagInventoryMappingContainer
																								{...props}
																							/>
																						</AdTagsContainer>
																					)}
																				/>
																				<Route
																					path={`${path}/:id`}
																					render={(props) => (
																						<CreateAdTagContainer
																							{...props}
																						/>
																					)}
																				/>
																				<Route
																					exact
																					path={`${path}/`}
																					render={(props) => (
																						<AdTagsContainer {...props} />
																					)}
																				/>
																			</Switch>
																		);
																	}}
																	authorisationField={
																		FeatureAuthorisationFlag.AD_TAG.value
																	}
																/>
																<ProtectedRoute
																	exact
																	path={ID_TO_PATH_MAPPING.directAuction}
																	component={OpenAuctionContainer}
																	authorisationField={
																		FeatureAuthorisationFlag.DIRECT_AUCTION.value
																	}
																/>
																<ProtectedRoute
																	path="/inventory"
																	render={(inventoryProps) => {
																		const { match } = inventoryProps;
																		const { path } = match;
																		return (
																			<InventoryContainer {...inventoryProps}>
																				{({
																					refetch: refetchInventoryList,
																					resetCurrentPageIndex,
																				}) => {
																					return (
																						<Switch>
																							<Route
																								exact
																								path="/inventory/create-inventory"
																								render={() => (
																									<CreateInventorySlider
																										refetchInventoryList={
																											refetchInventoryList
																										}
																										resetCurrentPageIndex={
																											resetCurrentPageIndex
																										}
																										{...inventoryProps}
																									/>
																								)}
																							/>
																							<Route
																								exact
																								path="/inventory/healthcare-identity/:id"
																								render={(
																									editInventoryProps
																								) => {
																									const {
																										match: {
																											params: {
																												id,
																											},
																										},
																									} =
																										editInventoryProps;
																									if (
																										!id ||
																										!isNumber(id)
																									) {
																										return (
																											<Redirect to="/inventory" />
																										);
																									}
																									return (
																										<CreateHealthCareIdentitySlider
																											refetchInventoryList={
																												refetchInventoryList
																											}
																											resetCurrentPageIndex={
																												resetCurrentPageIndex
																											}
																											inventoryId={
																												id
																											}
																											{...editInventoryProps}
																											{...inventoryProps}
																										/>
																									);
																								}}
																							/>
																							<Route
																								exact
																								path="/inventory/edit-inventory/:id"
																								render={(
																									editInventoryProps
																								) => {
																									const {
																										match: {
																											params: {
																												id,
																											},
																										},
																									} =
																										editInventoryProps;
																									if (
																										!id ||
																										!isNumber(id)
																									) {
																										return (
																											<Redirect to="/inventory" />
																										);
																									}
																									return (
																										<CreateInventorySlider
																											refetchInventoryList={
																												refetchInventoryList
																											}
																											resetCurrentPageIndex={
																												resetCurrentPageIndex
																											}
																											inventoryId={
																												id
																											}
																											{...editInventoryProps}
																											{...inventoryProps}
																										/>
																									);
																								}}
																							/>
																							<Route
																								exact
																								path={`${path}/uploadList`}
																								render={() => {
																									return (
																										<UploadListContainer
																											refetchInventoryList={
																												refetchInventoryList
																											}
																											resetCurrentPageIndex={
																												resetCurrentPageIndex
																											}
																											{...inventoryProps}
																										/>
																									);
																								}}
																							/>
																							<Route
																								exact
																								path={`${path}/:inventoryId/viewCategories`}
																								render={() => {
																									return (
																										<ViewCategoriesContainer
																											{...inventoryProps}
																										/>
																									);
																								}}
																							/>
																						</Switch>
																					);
																				}}
																			</InventoryContainer>
																		);
																	}}
																	authorisationField={
																		FeatureAuthorisationFlag.INVENTORY.value
																	}
																/>
																<ProtectedRoute
																	path="/deals"
																	render={() => {
																		return (
																			<Switch>
																				<Route
																					path="/deals/sent"
																					render={(dealSentProps) => (
																						<DealsContainer
																							key="deals_sent"
																							{...dealSentProps}
																						>
																							{({ onRefetchDeals }) => {
																								return (
																									<Switch>
																										<Route
																											exact
																											path={`${ID_TO_PATH_MAPPING.deals}/${SENT_ROUTE}/${EDIT_TAG}/:id`}
																											render={() => (
																												<EditAdTagSlider
																													onRefetchDeals={
																														onRefetchDeals
																													}
																												/>
																											)}
																										/>
																										<Route
																											exact
																											path={`${ID_TO_PATH_MAPPING.deals}/${SENT_ROUTE}/${MAP_TAG}/:id`}
																											render={() => (
																												<EditAdTagSlider
																													onRefetchDeals={
																														onRefetchDeals
																													}
																												/>
																											)}
																										/>
																										<Route
																											exact
																											path={`${ID_TO_PATH_MAPPING.deals}/${SENT_ROUTE}/${GET_PIXEL}/:id`}
																											component={
																												GetPixelSlider
																											}
																										/>
																									</Switch>
																								);
																							}}
																						</DealsContainer>
																					)}
																				/>
																				<Route
																					path="/deals/received"
																					render={(dealRecvProps) => (
																						<DealsContainer
																							key="deals_recv"
																							{...dealRecvProps}
																						>
																							{({ onRefetchDeals }) => {
																								return (
																									<Switch>
																										<Route
																											exact
																											path={`${ID_TO_PATH_MAPPING.deals}/${RECEIVED_ROUTE}/${EDIT_TAG}/:id`}
																											render={() => (
																												<EditAdTagSlider
																													onRefetchDeals={
																														onRefetchDeals
																													}
																												/>
																											)}
																										/>
																										<Route
																											exact
																											path={`${ID_TO_PATH_MAPPING.deals}/${RECEIVED_ROUTE}/${MAP_TAG}/:id`}
																											render={() => (
																												<EditAdTagSlider
																													onRefetchDeals={
																														onRefetchDeals
																													}
																												/>
																											)}
																										/>
																										<Route
																											exact
																											path={`${ID_TO_PATH_MAPPING.deals}/${RECEIVED_ROUTE}/${GET_PIXEL}/:id`}
																											component={
																												GetPixelSlider
																											}
																										/>
																									</Switch>
																								);
																							}}
																						</DealsContainer>
																					)}
																				/>
																				<Route
																					exact
																					path="/deals"
																					render={() => (
																						<Redirect to="/deals/sent" />
																					)}
																				/>
																			</Switch>
																		);
																	}}
																	authorisationField={
																		FeatureAuthorisationFlag.DEALS.value
																	}
																/>
																<Route path="/" component={redirectToFirstAuthorized} />
															</Switch>
														</HomeContainer>
													);
												}}
											/>
										</Switch>
									</Suspense>
								</MainContainerWithRouter>
							</React.Fragment>
						)}
					/>
				</Switch>
			</Suspense>
		</React.Fragment>
	</App>
);
