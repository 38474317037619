// @flow

import { Logger } from "@deepintent/react-component-lib-core";

class LoggerManager {
	init = () => {
		if (window.Logger) {
			LoggerManager.getContext().error("Logger_ALREADY_INIT", {
				message:
					"Logger can only be initialized once. Please remove calls initializing " +
					"the logger more than once.",
				location: "Logger::init",
			});
		} else {
			window.Logger = new Logger({ level: Logger.DEBUG });
		}
	};

	static getContext(): Logger {
		return window.Logger;
	}
}

export default LoggerManager;
