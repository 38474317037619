// @flow

import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import type { RouteProps } from "react-router-dom";
import AppContext from "./HomeContainer/AppContext";
import Constant from "../utils/Constants";

type Props = RouteProps & {
	authorisationField: string,
};

const { ID_TO_PATH_MAPPING } = Constant;

const ProtectedRoute = (props: Props) => {
	const { authorisationField, render: renderProp, component: Component, ...others } = props;
	const { menuFeaturesAuthorisationConfig, featuresAccessLoading } = useContext(AppContext);
	const currentAuthorisationField = menuFeaturesAuthorisationConfig?.[authorisationField];
	return (
		<Route
			{...others}
			render={localProps => {
				if (!featuresAccessLoading) {
					if (currentAuthorisationField) {
						if (typeof renderProp === "function") {
							return renderProp(localProps);
						}
						if (Component) {
							return <Component {...localProps} />;
						}
					}
					if (typeof renderProp === "function" || Component)
						return <Redirect to={ID_TO_PATH_MAPPING.notAuthorized} />;
				}
				return null;
			}}
		/>
	);
};

export default ProtectedRoute;
