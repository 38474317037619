// @flow

// Helpers to interact with apollo client cache
import { ApolloClient } from "apollo-client";

/**
 * Helper to reset some part of cached client query from apollo cache
 * @param client
 */
export default function clearItemsFromCache(client: ApolloClient) {
	try {
		// refetching the observable queries would refresh the cache
		client.reFetchObservableQueries();
	} catch (error) {
		console.error("error while resetting client cache");
	}
}
