// @flow

import React from "react";
import type { Node } from "react";
import isEqual from "lodash/isEqual";
import User from "../../models/User";
import IntercomManager from "../../utils/manager/IntercomManager";
import type { IntercomUser } from "../../utils/manager/IntercomManager";
import Constant from "../../utils/Constants";

export type UserDetails = {
	user: User,
};

type Props = {
	children: Node,
	userDetails: UserDetails,
	userHash: string,
};

type State = {
	user: ?User,
};

export default class AppContainer extends React.Component<Props, State> {
	state = {
		user: null,
	};

	componentDidMount() {
		const {
			userDetails: { user },
			userHash,
		} = this.props;
		if (user) {
			const intercomUser: IntercomUser = {
				user_id: user.userId,
				name: user.name,
				email: user.email,
				locale: user.locale || Constant.LOCALE.DEFAULT_LOCALE,
				email_verified: user.emailVerified,
				resource_type: Constant.RESOURCE_TYPE,
				application_name: Constant.APPLICATION_NAME,
				user_hash: userHash,
			};
			IntercomManager.setIntercomUser(intercomUser);
		}
	}

	static getDerivedStateFromProps(nextProps: Props, prevState: State) {
		const {
			userDetails: { user },
		} = nextProps;

		const { user: userState } = prevState;

		if (user && !isEqual(user, userState)) {
			return {
				user,
			};
		}

		return null;
	}

	render() {
		const { children } = this.props;

		return <React.Fragment>{children}</React.Fragment>;
	}
}
