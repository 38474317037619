// @flow

import * as React from "react";
import { DiToasterManager, DiBaseMessage } from "@deepintent/react-component-lib-core";
import Constants from "../../utils/Constants";
import EventPublisherManager from "../../utils/manager/EventPublisherManager";

type State = {
	formMessages: ?Array<DiBaseMessage>,
	showAnimation: ?boolean,
};

export default class NotificationManager extends React.PureComponent<any, State> {
	formMessagesObserverId: string;

	state = {
		formMessages: null,
		showAnimation: true,
	};

	componentWillMount() {
		this.subscribeEventPublisher();
	}

	componentWillUnmount() {
		if (this.formMessagesObserverId && EventPublisherManager.getContext()) {
			EventPublisherManager.getContext().unsubscribe(this.formMessagesObserverId);
		}
	}

	subscribeEventPublisher = () => {
		if (EventPublisherManager.getContext()) {
			this.formMessagesObserverId = EventPublisherManager.getContext().subscribe(
				Constants.EVENT_PUBLISHER.EVENTS.FORM_MESSAGES,
				this.onFormMessagesPublished
			);
		}
	};

	onFormMessagesPublished = (messages: ?Array<DiBaseMessage>) => {
		if (Array.isArray(messages) && messages.length) {
			let formMessages = [...messages];
			const { formMessages: messageList } = this.state;

			if (Array.isArray(messageList)) {
				formMessages = formMessages.concat([...messageList]);
			}

			this.setState({
				formMessages,
				showAnimation: true,
			});
		}
	};

	getToastFormMessages() {
		const { formMessages } = this.state;
		if (Array.isArray(formMessages)) {
			return formMessages.map(error => {
				return {
					messageData: error,
					messageClassName: "form-toaster-message",
				};
			});
		}
	}

	onFormToasterDestroyed = (index: number) => {
		const { formMessages } = this.state;
		if (Array.isArray(formMessages)) {
			const data = [...formMessages];

			data.splice(index, 1);
			this.setState({
				formMessages: data,
				showAnimation: false,
			});
		}
	};

	render() {
		const { showAnimation } = this.state;
		return (
			<DiToasterManager
				key="form-message-toaster"
				data={this.getToastFormMessages()}
				showAnimation={showAnimation}
				onToasterCardDestroyed={this.onFormToasterDestroyed}
			/>
		);
	}
}
