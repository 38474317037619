// @flow

import ConfigManager from "./ConfigManager";
import Constants from "../Constants";
import type { ResourceType, ApplicationName } from "../../types";

export type IntercomUser = {
	name: string,
	email: string,
	user_id: number,
	email_verified: boolean,
	resource_type: ResourceType,
	application_name: ApplicationName,
	locale: string,
	user_hash: string,
};

class IntercomManager {
	intercom: Object;

	config: Object = ConfigManager.getContext();

	init(appId?: string, options?: Object) {
		const intercomAppId = appId || this.config.intercom.appId;

		window.intercomSettings = {
			...Constants.INTERCOM.SETTINGS,
			...options,
			app_id: intercomAppId,
		};

		const w = window;
		const ic = w.Intercom;
		if (typeof ic === "function") {
			ic("reattach_activator");
			ic("update", w.intercomSettings);
		} else {
			const i = function() {
				i.c(arguments);
			};
			i.q = [];
			i.c = function(args) {
				i.q.push(args);
			};
			w.Intercom = i;

			if (w.attachEvent) {
				w.attachEvent("onload", IntercomManager.load.bind(null, intercomAppId));
			} else {
				w.addEventListener("load", IntercomManager.load.bind(null, intercomAppId), false);
			}
		}

		this.intercom = w.Intercom;
	}

	static load(appId: string) {
		const script = document.createElement("script");
		script.type = "text/javascript";
		script.async = true;
		script.src = Constants.INTERCOM.CDN_LINK.replace("__APP_ID__", appId);
		const x = document.getElementsByTagName("script");
		if (x.length > 0) {
			x[0].parentNode.insertBefore(script, x[0]);
		}
	}

	static setIntercomUser = (user: IntercomUser) => {
		if (window.Intercom) {
			window.intercomSettings = {
				...window.intercomSettings,
				...user,
			};
			window.Intercom("update", window.intercomSettings);
		}
	};

	static shutdown() {
		window.Intercom("shutdown");
	}

	getContext = () => {
		return this.intercom;
	};
}

export default IntercomManager;
