// @flow

import React from "react";
import { DiCoreConstant, DiSpinner, SpinnerTypeEnum } from "@deepintent/react-component-lib-core";

const { DATA_DI_ID } = DiCoreConstant;

const loadingStyle = {
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	height: "100vh",
	width: "100vw",
	position: "fixed",
	top: 0,
	left: 0,
};

const AsyncLoader = ({ isLoading, error }: { isLoading: boolean, error: any }) => {
	// Handle the loading state
	if (isLoading) {
		return (
			<div style={loadingStyle}>
				<DiSpinner {...{ [DATA_DI_ID]: "async-loader-spinner" }} type={SpinnerTypeEnum.PRIMARY.name} />
			</div>
		);
	}
	// Handle the error state
	else if (error) {
		console.log("ERROR ", error);

		return <div>Sorry, there was a problem loading the page.</div>;
	} else {
		return null;
	}
};

export default AsyncLoader;
