// @flow

import FeatureAuthorisationFlag from "../enums/FeatureAuthorisationFlag";

const config: Object = {
	[FeatureAuthorisationFlag.PUBLISH_RX.name]: {
		enabled: true,
		features: {
			logicalGroup: {
				OR: {
					PUB_OUTCOMES_DASHBOARD: {
						accessRoles: {
							DEFAULT: {
								scope: "READ",
							},
						},
					},
					PUB_OUTCOMES_REGISTRATION: {
						accessRoles: {
							DEFAULT: {
								scope: "READ",
							},
						},
					},
				},
			},
		},
	},
	[FeatureAuthorisationFlag.DASHBOARD.name]: {
		enabled: true,
		features: {
			logicalGroup: {
				OR: {
					PUB_DASHBOARD_GENERAL: {
						accessRoles: {
							DEFAULT: {
								scope: "READ",
							},
						},
					},
				},
			},
		},
	},
	[FeatureAuthorisationFlag.INVENTORY.name]: {
		enabled: true,
		features: {
			logicalGroup: {
				OR: {
					PUB_INVENTORY_GENERAL: {
						accessRoles: {
							DEFAULT: {
								scope: "READ",
							},
						},
					},
				},
			},
		},
	},
	[FeatureAuthorisationFlag.AD_TAG.name]: {
		enabled: true,
		features: {
			logicalGroup: {
				OR: {
					PUB_AD_TAG_GENERAL: {
						accessRoles: {
							DEFAULT: {
								scope: "READ",
							},
						},
					},
				},
			},
		},
	},
	[FeatureAuthorisationFlag.AUDIENCE.name]: {
		enabled: true,
		features: {
			logicalGroup: {
				OR: {
					PUB_AUDIENCE_GENERAL: {
						accessRoles: {
							DEFAULT: {
								scope: "READ",
							},
						},
					},
				},
			},
		},
	},
	[FeatureAuthorisationFlag.DIRECT_AUCTION.name]: {
		enabled: true,
		features: {
			logicalGroup: {
				OR: {
					PUB_AUCTION_GENERAL: {
						accessRoles: {
							DEFAULT: {
								scope: "READ",
							},
						},
					},
				},
			},
		},
	},
	[FeatureAuthorisationFlag.DEALS.name]: {
		enabled: true,
		features: {
			logicalGroup: {
				OR: {
					PUB_DEAL_GENERAL: {
						accessRoles: {
							DEFAULT: {
								scope: "READ",
							},
						},
					},
				},
			},
		},
	},
	[FeatureAuthorisationFlag.CREATIVE_LIBRARY.name]: {
		enabled: true,
		features: {
			logicalGroup: {
				OR: {
					PUB_CREATIVE_GENERAL: {
						accessRoles: {
							DEFAULT: {
								scope: "READ",
							},
						},
					},
				},
			},
		},
	},
};

export default config;
