// @flow

import React from "react";
import { DiSpinner, SpinnerTypeEnum } from "@deepintent/react-component-lib-core";

const fullScreenStyles = {
	position: "absolute",
	top: "0",
	bottom: "0",
	left: "0",
	right: "0",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
};

const FullscreenLoader = () => {
	return (
		<div style={{ ...fullScreenStyles }}>
			<DiSpinner type={SpinnerTypeEnum.PRIMARY.name} />
		</div>
	);
};

export default FullscreenLoader;
