// @flow

import React from "react";
import type { Element } from "react";
import type { UserACLRule } from "@deepintent/react-component-internal";
import { ComponentACLConsumer } from "@deepintent/react-authorisation-lib";
import type { MenuFeaturesAuthorisationConfig } from "../../types";
import FeatureAuthorisationFlag from "../../enums/FeatureAuthorisationFlag";

type Props = {
	userACLRule: UserACLRule,
	children: (authConfig: MenuFeaturesAuthorisationConfig) => Element<any>,
};

const MenuFeaturesWrapper = (props: Props) => {
	const { children } = props || {};

	return (
		<ComponentACLConsumer componentKey={FeatureAuthorisationFlag.PUBLISH_RX.name}>
			{({ isAuthorised: isPublishRxAuthorized }) => {
				return (
					<ComponentACLConsumer componentKey={FeatureAuthorisationFlag.DASHBOARD.name}>
						{({ isAuthorised: isDashboardAuthorized }) => {
							return (
								<ComponentACLConsumer componentKey={FeatureAuthorisationFlag.INVENTORY.name}>
									{({ isAuthorised: isInventoryAuthorized }) => {
										return (
											<ComponentACLConsumer componentKey={FeatureAuthorisationFlag.AD_TAG.name}>
												{({ isAuthorised: isAdTagAuthorized }) => {
													return (
														<ComponentACLConsumer
															componentKey={FeatureAuthorisationFlag.AUDIENCE.name}
														>
															{({ isAuthorised: isAudienceAuthorized }) => {
																return (
																	<ComponentACLConsumer
																		componentKey={
																			FeatureAuthorisationFlag.DIRECT_AUCTION.name
																		}
																	>
																		{({
																			isAuthorised: isDirectAuctionAuthorized,
																		}) => {
																			return (
																				<ComponentACLConsumer
																					componentKey={
																						FeatureAuthorisationFlag.DEALS
																							.name
																					}
																				>
																					{({
																						isAuthorised: isDealsAuthorized,
																					}) => {
																						return (
																							<ComponentACLConsumer
																								componentKey={
																									FeatureAuthorisationFlag
																										.CREATIVE_LIBRARY
																										.name
																								}
																							>
																								{({
																									isAuthorised: isCreativeLibraryAuthorized,
																								}) => {
																									return children({
																										// Hide PublishRx feature
																										isPublishRxAuthorized: false,
																										isDashboardAuthorized,
																										isInventoryAuthorized,
																										isAdTagAuthorized,
																										isAudienceAuthorized,
																										isDirectAuctionAuthorized,
																										isDealsAuthorized,
																										isCreativeLibraryAuthorized,
																									});
																								}}
																							</ComponentACLConsumer>
																						);
																					}}
																				</ComponentACLConsumer>
																			);
																		}}
																	</ComponentACLConsumer>
																);
															}}
														</ComponentACLConsumer>
													);
												}}
											</ComponentACLConsumer>
										);
									}}
								</ComponentACLConsumer>
							);
						}}
					</ComponentACLConsumer>
				);
			}}
		</ComponentACLConsumer>
	);
};
export default MenuFeaturesWrapper;
