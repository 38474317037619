// @flow

import React, { useRef } from "react";
import type { Node } from "react";
import isEqual from "lodash/isEqual";
import { buildApplicationComponentAclRule, buildUserAclRule } from "@deepintent/authorisation-lib";
import { ComponentACLProvider } from "@deepintent/react-authorisation-lib";
import { EnhancedUserACLRule, displayErrorMessages, LoginUtils } from "@deepintent/react-component-internal";
import type { EnhancedUserACLRuleRenderProps, UserACLRule } from "@deepintent/react-component-internal";
import EventPublisherManager from "../../utils/manager/EventPublisherManager";
import usePrevious from "../../components/shared/Hooks/usePrevious";
import appConfig from "../../applicationACL/AppConfig";
import ConfigManager from "../../utils/manager/ConfigManager";

const { componentACLRule } = appConfig;

type Props = {
	clientDetails: Object,
	userId: number,
	children: ({
		userACLRuleLoading: boolean,
		userACLRule: UserACLRule,
	}) => Node,
};

const UserACLWrapper = (props: Props) => {
	const { clientDetails } = props;
	const prevClientDetails = usePrevious(clientDetails);
	const refetch = useRef(null);

	const { children, userId } = props;
	const applicationACL = buildApplicationComponentAclRule(componentACLRule);
	const prevResourceId = prevClientDetails?.activeResourceId;

	if (
		prevResourceId !== -1 &&
		!isEqual(clientDetails, prevClientDetails) &&
		refetch &&
		typeof refetch.current === "function"
	) {
		refetch.current();
	}
	const loginUtils = new LoginUtils(ConfigManager.getContext());
	let orgId = loginUtils.getActiveOrganisationId() || null;
	const resourceId = clientDetails?.activeResourceId;
	const skip = resourceId === -1 || !orgId;

	return (
		<EnhancedUserACLRule skip={skip}>
			{({
				userACLRule,
				loading,
				errors: userACLRuleErrors,
				refetch: userACLRefetch,
			}: EnhancedUserACLRuleRenderProps) => {
				refetch.current = userACLRefetch;
				if (userACLRuleErrors && userACLRuleErrors.graphQLErrors) {
					displayErrorMessages(
						userACLRuleErrors,
						EventPublisherManager,
						"Error while fetching user acl rule"
					);
				}
				let userACL = null;
				const user = {
					userId,
					role: null,
				};

				if (userACLRule) {
					userACL = buildUserAclRule(userACLRule);
					user.role = userACLRule.role || null;
				}
				let userACLRuleLoading = loading;
				if (skip) {
					userACLRuleLoading = true;
				}
				return (
					<ComponentACLProvider
						applicationComponentACLRule={applicationACL}
						user={user}
						userAclRule={userACL}
					>
						{children({ userACLRuleLoading, userACLRule })}
					</ComponentACLProvider>
				);
			}}
		</EnhancedUserACLRule>
	);
};

export default UserACLWrapper;
