// @flow

import { useEffect, useRef } from "react";

const usePrevious = (value: any) => {
	const ref = useRef();

	useEffect(() => {
		ref.current = value;
	}, [value]); // when value changes

	// return previous value
	return ref.current;
};

export default usePrevious;
