// @flow

import * as React from "react";
import { DiWarningIcon } from "@deepintent/react-component-lib-core";
import type { ClientDetails } from "../../../types";
import OverlayMessage from "../OverlayMessage/OverlayMessage";

type Props = {
	children: React.Node,
	clientDetails: ?ClientDetails,
	messageText: string,
	subtext: string,
};

const Icon = () => (
	<DiWarningIcon
		style={{
			marginBottom: "20px",
			height: "70px",
			width: "70px",
		}}
	/>
);

const NoActiveResourceOverlay = (props: Props) => {
	const { clientDetails, messageText, subtext, children } = props;

	return clientDetails &&
		clientDetails.activeResources &&
		Array.isArray(clientDetails.activeResources) &&
		clientDetails.activeResources.length > 0 ? (
		<React.Fragment>{children}</React.Fragment>
	) : (
		<OverlayMessage messageIcon={<Icon />} messageText={messageText} subtext={subtext}>
			{children}
		</OverlayMessage>
	);
};

export default NoActiveResourceOverlay;
