// @flow

import React, { useState, useEffect } from "react";
import { DiForceReloadModal } from "@deepintent/react-component-internal";
import Constants from "../utils/Constants";

export default function GracefulApiHandling() {
	const [showError, setShowError] = useState<boolean>(false);

	const onDurationEnd = () => {
		setShowError(false);
		window.location.reload();
	};

	useEffect(() => {
		const apiErrorHandler = () => {
			setShowError(true);
		};
		document.addEventListener(Constants.GRACEFUL_API_EVENT, apiErrorHandler);
		return () => {
			document.removeEventListener(Constants.GRACEFUL_API_EVENT, apiErrorHandler);
		};
	}, []);

	if (showError) return <DiForceReloadModal show={showError} onDurationEnd={onDurationEnd} />;
	return null;
}
