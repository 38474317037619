// @flow

import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "react-apollo";
import { PublisherHeader } from "@deepintent/react-component-internal";
import ConfigManager from "../../utils/manager/ConfigManager";
import Constants from "../../utils/Constants";
import styles from "./header.module.scss";

type Props = {
	application: string,
};
type ProvidedProps = {
	history: any,
	location: any,
};

export const ID_TO_PATH_REGEX = {
	adtag: new RegExp(/\/adtag/),
	audience: new RegExp(/\/audience/),
	deals: new RegExp(/\/deals/),
	inventory: new RegExp(/\/inventory/),
	dashboard: new RegExp(/\/dashboard/),
	publishRx: new RegExp(/\/publishRx/),
	creativeLibrary: new RegExp(/\/creativeLibrary/),
	directAuction: new RegExp(/\/directAuction/),
	outcomes: new RegExp(/\/outcomes/),
};

const { PUBLISHER_APP_VERSION } = Constants;

class Header extends React.Component<ProvidedProps & Props> {
	getSelectedSectionName = () => {
		let CurrentPageTitle = "";
		const currentLocation = window.location.href;
		Object.keys(Constants.ID_TO_PATH_MAPPING).map(sectionLink => {
			if (currentLocation && currentLocation.includes(sectionLink)) {
				CurrentPageTitle = Constants.PUBLISHER_ROUTE_HEADLINE[sectionLink];
			}
		});
		return CurrentPageTitle;
	};

	// for every new section an entry needs to be made in the redirectToSection const
	redirectOnPublisherChange = () => {
		const { location, history } = this.props;

		const redirectToSection = {
			// eslint-disable-next-line spellcheck/spell-checker
			adtag: "/adtag",
			audience: "/audience",
			deals: "/deals",
			inventory: "/inventory",
			dashboard: "/dashboard",
			publishRx: "/publishRx",
			creativeLibrary: "/creativeLibrary",
			directAuction: "/directAuction",
			outcomes: "/outcomes",
		};

		const pageIdKeys = Object.keys(ID_TO_PATH_REGEX);

		for (let index = 0; index < pageIdKeys.length; ++index) {
			const pageId = pageIdKeys[index];
			const pagePathRegex = ID_TO_PATH_REGEX[pageId];

			if (location && pagePathRegex.test(location.pathname)) {
				// Refresh current route without reloading the whole page
				// reference:- https://stackoverflow.com/a/51332885/7504603
				history.push(Constants.TEMP_REDIRECT_URL);

				// setTimeout is used so that the unmount lifecycle is called for previous route
				return setTimeout(() => history.replace(redirectToSection[pageId]));
			}
		}

		// if no entry is found for the section then the default redirect will be made
		return history.push("/");
	};

	confirmPublisherChange = () => {
		const { location } = this.props;

		/**
		 * This mapping is used to find the pages with forms to fill
		 */
		const FORM_PAGES_PATH_REGEX = {
			adTagCreateEdit: new RegExp(/\/adtag\/(create|[0-9]+|get\/[0-9]+)/),
			directAuction: new RegExp(/\/directAuction/),
			dealsCreateEdit: new RegExp(/\/deals\/(create|edit\/[0-9]+)/),
			creativePolicy: new RegExp(/\/creativeLibrary\/(managepolicies|[0-9]+)/),
		};

		const pageIdKeys = Object.keys(FORM_PAGES_PATH_REGEX);

		return pageIdKeys.some(pageId => {
			const pagePathRegex = FORM_PAGES_PATH_REGEX[pageId];
			return !!(location && pagePathRegex.test(location.pathname));
		});
	};

	render() {
		const { application } = this.props;
		const currentPageTitle = this.getSelectedSectionName();

		return (
			<PublisherHeader
				isUserDropdownEnabled={false}
				appVersion={PUBLISHER_APP_VERSION}
				config={ConfigManager.getContext()}
				isNotificationEnabled={false}
				currentPageTitle={currentPageTitle}
				redirectOnSeatChange={this.redirectOnPublisherChange}
				confirmSeatChange={this.confirmPublisherChange}
				applicationName={application}
				classes={{ helpIcon: styles.helpIcon }}
			/>
		);
	}
}

export default compose(withRouter)(Header);
