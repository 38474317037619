const DashboardConstant = {
	METRICS: {
		mediaSpend: {
			displayName: "Revenue",
			queryFieldName: "MEDIA_SPEND",
			dataFieldName: "publisherStats.mediaSpend",
			dataFormat: "currency",
			prefix: "$",
		},
		opportunity: {
			displayName: "Opportunity",
			queryFieldName: "OPPORTUNITY",
			dataFieldName: "publisherStats.opportunity",
			dataFormat: "integer",
		},
		impressionsBid: {
			displayName: "Bids",
			queryFieldName: "IMPRESSIONS_BID",
			dataFieldName: "publisherStats.impressionsBid",
			dataFormat: "integer",
		},
		answered: {
			displayName: "Answered",
			queryFieldName: "ANSWERED",
			dataFieldName: "publisherStats.answered",
			dataFormat: "integer",
		},
		impressionsWon: {
			displayName: "Impressions",
			queryFieldName: "IMPRESSIONS_WON",
			dataFieldName: "publisherStats.impressionsWon",
			dataFormat: "integer",
		},
		publisherWinRate: {
			displayName: "Win Rate",
			queryFieldName: "PUBLISHER_WIN_RATE",
			dataFieldName: "publisherStats.publisherWinRate",
			dataFormat: "decimal",
			postfix: "%",
		},
		fillRate: {
			displayName: "Fill Rate",
			queryFieldName: "FILL_RATE",
			dataFieldName: "publisherStats.fillRate",
			dataFormat: "decimal",
			postfix: "%",
		},
		mediaCpm: {
			displayName: "Avg. CPM",
			queryFieldName: "MEDIA_CPM",
			dataFieldName: "publisherStats.mediaCpm",
			dataFormat: "currency",
			prefix: "$",
		},
		publisherEvent: {
			displayName: "Events",
			queryFieldName: "PUBLISHER_EVENT",
			dataFieldName: "publisherStats.publisherEvent",
			dataFormat: "integer",
		},
		billablePublisherEvent: {
			displayName: "Billable Events",
			queryFieldName: "BILLABLE_PUBLISHER_EVENT",
			dataFieldName: "publisherStats.billablePublisherEvent",
			dataFormat: "integer",
		},
		nonBillablePublisherEvent: {
			displayName: "Non-Billable Events",
			queryFieldName: "NON_BILLABLE_PUBLISHER_EVENT",
			dataFieldName: "publisherStats.nonBillablePublisherEvent",
			dataFormat: "integer",
		},
		uniqueUserCount: {
			displayName: "Unique Users",
			queryFieldName: "UNIQUE_USER_COUNT",
			dataFieldName: "publisherStats.uniqueUserCount",
			dataFormat: "integer",
		},
	},
	PUBLISHER_METRICS: [
		// list of metrics that fall under publisher, list values should from METRICS
		"mediaSpend",
		"opportunity",
		"answered",
		"fillRate",
		"impressionsWon",
		"publisherWinRate",
		"mediaCpm",
	],
	ADVERTISER_METRICS: [
		// list of metrics that fall under advertiser, list values should from METRICS
		"mediaSpend",
		"impressionsBid",
		"impressionsWon",
		"mediaCpm",
		"publisherEvent",
		"billablePublisherEvent",
		"nonBillablePublisherEvent",
		"uniqueUserCount",
	],
	DIMENSIONS: {
		INVENTORY: {
			displayName: "Inventory",
			dataFieldName: "inventoryId",
			queryFieldName: "INVENTORY_ID",
			metricSet: "publisher_metric",
		},
		ADVERTISER: {
			displayName: "Advertiser",
			dataFieldName: "advertiser.name",
			dataIdCodeFieldName: "advertiser.id",
			queryFieldName: "ADVERTISER_ID",
			metricSet: "advertiser_metric",
		},
		ORGANIZATION: {
			displayName: "Organization",
			dataFieldName: "advertiserOrganization.name",
			dataIdCodeFieldName: "advertiserOrganization.id",
			queryFieldName: "ADVERTISER_ORGANIZATION_ID",
			searchQueryField: "name,organizationId",
			metricSet: "advertiser_metric",
		},
		TAGID: {
			displayName: "Tag",
			dataFieldName: "tag.name",
			queryFieldName: "TAG_ID",
			searchQueryField: "name,tagId",
			metricSet: "publisher_metric",
		},
		DEALID: {
			displayName: "Deals",
			dataFieldName: "deal.name",
			dataIdCodeFieldName: "deal.id",
			queryFieldName: "DEAL_ID",
			metricSet: "advertiser_metric",
		},
		SOURCE: {
			displayName: "Source",
			dataFieldName: "source",
			queryFieldName: "SOURCE",
			searchQueryField: "SOURCE",
			metricSet: "publisher_metric",
		},
		OS: {
			displayName: "OS",
			dataFieldName: "os",
			queryFieldName: "OS",
			metricSet: "publisher_metric",
		},
		BROWSER: {
			displayName: "Browser",
			dataFieldName: "browser",
			queryFieldName: "BROWSER",
			searchQueryField: "BROWSER",
			metricSet: "publisher_metric",
		},
		DEVICETYPE: {
			displayName: "Device Type",
			dataFieldName: "deviceType",
			queryFieldName: "DEVICE_TYPE",
			searchQueryField: "DEVICE_TYPE",
			metricSet: "publisher_metric",
		},
		COUNTRY: {
			displayName: "Country",
			dataFieldName: "countryDimension.name",
			dataIdCodeFieldName: "countryDimension.code",
			queryFieldName: "COUNTRY",
			metricSet: "publisher_metric",
		},
		REGION: {
			displayName: "Region",
			dataFieldName: "regionDimension.name",
			dataIdCodeFieldName: "regionDimension.code",
			queryFieldName: "REGION",
			searchQueryField: "REGION",
			metricSet: "publisher_metric",
		},
		DMA: {
			displayName: "DMA",
			dataFieldName: "dmaDimension.name",
			dataIdCodeFieldName: "dmaDimension.code",
			queryFieldName: "DMA",
			searchQueryField: "DMA",
			metricSet: "publisher_metric",
		},
		CREATIVE_AD_SIZE: {
			displayName: "Ad Size",
			dataFieldName: "creativeAdSize",
			queryFieldName: "CREATIVE_AD_SIZE",
			searchQueryField: "CREATIVE_AD_SIZE",
			metricSet: "publisher_metric",
		},
		HOSTING_TYPE: {
			displayName: "Hosting Type",
			dataFieldName: "hostingType",
			queryFieldName: "HOSTING_TYPE",
			searchQueryField: "HOSTING_TYPE",
			metricSet: "advertiser_metric",
		},
	},
};

export default DashboardConstant;
