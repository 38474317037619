// @flow

class ConfigManager {
	static config: Object;

	init = () => {
		// $FlowFixMe
		ConfigManager.config = __CONFIG__;
	};

	static getContext() {
		if (!ConfigManager.config) {
			ConfigManager.config = __CONFIG__;
		}
		return ConfigManager.config;
	}
}

export default ConfigManager;
