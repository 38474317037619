// @flow

import { InMemoryCache, ApolloLink, split, from } from "@apollo/client";
import { getMainDefinition } from "apollo-utilities";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { diUploadFetch } from "@deepintent/react-apollo-utils";
import type { LoginUtils as LoginUtilsType } from "@deepintent/react-component-internal";
import Constants from "../utils/Constants";

const getAuthLink = (loginUtils: LoginUtilsType, localXVersionId: string | null, applicationId: string | null) => {
	return setContext(({ variables }, { headers }) => {
		const xVersionId = localXVersionId;
		const token = loginUtils.getToken();
		const orgId = loginUtils.getActiveOrganisationId() || null;
		const resourceId = loginUtils.getActiveResourceId() || "";
		// return the headers to the context so httpLink can read them
		return {
			headers: {
				...headers,
				authorization: token ? `Bearer ${token}` : null,
				"X-OrganizationId": orgId,
				"X-ApplicationId": applicationId,
				"X-ResourceId": resourceId,
				/*	Add "file-upload-v2" header if file upload request
				is v2 (identified by adding fileV2 in variables) which
				helps differentiate in gateway for correct middleware usage.
        */
				...("fileV2" in variables ? { "file-upload-v2": true } : {}),
				...(xVersionId ? { "X-versionid": xVersionId } : {}),
			},
		};
	});
};
export default getAuthLink;

export const getErrorLink = (loginUtils: LoginUtilsType) => {
	return onError(({ graphQLErrors, networkError, operation, response }) => {
		if (graphQLErrors) {
			graphQLErrors.map(({ message, locations, path, extensions }) => {
				if (extensions && extensions.code === Constants.LOGIN.CODES.UNAUTHENTICATED) {
					loginUtils.redirectToLogin();
				}
				if (extensions && extensions.code === Constants.GATEWAY_VERSION_MISMATCHED) {
					// Dispatching Custom Event for Graceful Api Handling
					const event = new CustomEvent(Constants.GRACEFUL_API_EVENT);
					document.dispatchEvent(event);

					return;
				}
				console.error(
					`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`
				);
			});
		}
		if (networkError) {
			console.error(`[Network error]: ${networkError}`);
			if (networkError.statusCode === 401) {
				loginUtils.logout();
			}
		}

		if (operation.operationName === "IgnoreErrorsQuery") {
			response.errors = null;
		}
	});
};

export const getWebsocketLink = (graphQlWebSocketUrl: string, loginUtils: Object) => {
	return new GraphQLWsLink(
		createClient({
			url: graphQlWebSocketUrl,
			connectionParams: {
				token: loginUtils.getToken(),
			},
		})
	);
};

export const getHttpLink = (graphQlApiUrl: string) => {
	return createUploadLink({ uri: graphQlApiUrl, fetch: diUploadFetch });
};

export const getRestLink = (graphQlWebSocketUrl: string, graphQlApiUrl: string, loginUtils: Object) => {
	const websocketLink = getWebsocketLink(graphQlWebSocketUrl, loginUtils);
	const apolloHttpLink = getHttpLink(graphQlApiUrl);
	return split(
		// split based on operation type
		({ query }) => {
			const { kind, operation } = getMainDefinition(query);
			return kind === "OperationDefinition" && operation === "subscription";
		},
		websocketLink,
		apolloHttpLink
	);
};

export const getAfterWareLink = (
	localXVersionId: string | null,
	setLocalVersionId: (localXVersionId: string | null) => void
) => {
	return new ApolloLink((operation, forward) => {
		return forward(operation).map(response => {
			const context = operation.getContext();
			const headers = context?.response?.headers;

			if (headers) {
				const xVersionId = headers.get("x-versionid");
				if (!localXVersionId || localXVersionId !== xVersionId) {
					setLocalVersionId?.(xVersionId);
				}
			}
			return response;
		});
	});
};

export const getApolloClientV2DefaultConfig = () => {
	const newCache = new InMemoryCache();
	const defaultConfig = {
		cache: newCache,
		defaultOptions: {
			watchQuery: {
				fetchPolicy: "cache-and-network",
				errorPolicy: "all",
			},
			query: {
				fetchPolicy: "cache-and-network",
				errorPolicy: "all",
			},
			mutate: {
				errorPolicy: "all",
			},
		},
		connectToDevTools: false,
	};
	return defaultConfig;
};

export const getApolloLinks = (params: Object) => {
	const { loginUtils, localXVersionId, setLocalXVersionId, graphQlWebSocketUrl, graphQlApiUrl, applicationId } =
		params || {};
	const authLink = getAuthLink(loginUtils, localXVersionId, applicationId);
	const afterWareLink = getAfterWareLink(localXVersionId, setLocalXVersionId);
	const errorLink = getErrorLink(loginUtils);
	const restLink = getRestLink(graphQlWebSocketUrl, graphQlApiUrl, loginUtils);
	return from([authLink, afterWareLink, errorLink, restLink]);
};
