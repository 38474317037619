// @flow

import React, { useRef } from "react";
import type { Node } from "react";
import isNil from "lodash/isNil";
import {
	EnhancedIntercomHashDataQuery,
	EnhancedVerifyUserQuery,
	LoginUtils,
} from "@deepintent/react-component-internal";
import AppContext, { DEFAULT_MENU_FEATURES_AUTHORISATION_CONFIG } from "./AppContext";
import AppContainer from "./AppContainer";
import ConfigManager from "../../utils/manager/ConfigManager";
import EventPublisherManager from "../../utils/manager/EventPublisherManager";
import FullScreenLoader from "../../components/shared/FullscreenLoader/FullscreenLoader";
import UserACLWrapper from "../UserACLWrapper/UserACLWrapper";
import MenuFeaturesWrapper from "../MenuFeaturesWrapper/MenuFeaturesWrapper";

type Props = {
	children: Node,
};

const AppWrapper = (props: Props) => {
	const { children } = props;
	const loginUtils = new LoginUtils(ConfigManager.getContext());
	const userHashData = useRef<string | null>(null);

	return (
		<EnhancedVerifyUserQuery>
			{({ userDetails, loading, errors }) => {
				if (loading) {
					return <FullScreenLoader />;
				}

				if (Array.isArray(errors) && loginUtils.isUnauthenticated(errors)) {
					loginUtils.redirectToLogin();
					return null;
				}

				if (userDetails) {
					return (
						<UserACLWrapper userId={userDetails?.user?.userId}>
							{({ userACLRuleLoading, userACLRule }) => (
								<MenuFeaturesWrapper userACLRule={userACLRule}>
									{menuFeaturesAuthorisationConfig => {
										const appContextValue = {
											featuresAccessLoading: userACLRuleLoading,
											userACLRule,
											currentUserDetails: userDetails,
											menuFeaturesAuthorisationConfig: userACLRuleLoading
												? DEFAULT_MENU_FEATURES_AUTHORISATION_CONFIG
												: menuFeaturesAuthorisationConfig,
											userACLRuleLoading,
										};
										return (
											<AppContext.Provider value={appContextValue}>
												<EnhancedIntercomHashDataQuery
													userId={userDetails?.user?.userId.toString()}
													eventPublisherManager={EventPublisherManager}
													skip={!isNil(userHashData.current)}
												>
													{({ userHash, loading: hashDataLoading }) => {
														if (userHashData.current === null && !isNil(userHash)) {
															userHashData.current = userHash;
														}

														return (
															!hashDataLoading && (
																<AppContainer
																	userDetails={userDetails}
																	userHash={userHash}
																>
																	<React.Fragment>{children}</React.Fragment>
																</AppContainer>
															)
														);
													}}
												</EnhancedIntercomHashDataQuery>
											</AppContext.Provider>
										);
									}}
								</MenuFeaturesWrapper>
							)}
						</UserACLWrapper>
					);
				}

				return null;
			}}
		</EnhancedVerifyUserQuery>
	);
};

export default AppWrapper;
