// @flow

class Enum {
	name: string;

	value: string;

	constructor(name: string, value: string) {
		if (name) {
			this.name = name;
			this.value = value;
		} else {
			throw new Error("name is required as a 1st parameter of Enum");
		}
	}
}

export default Enum;
