// @flow

import * as React from "react";
import { DiRow } from "@deepintent/react-component-lib-core";
import SideNav from "../../components/SideNav/SideNav";
import styles from "./homeContainer.module.scss";

type Props = {
	application: string,
	children: React.Node,
};

const HomeContainer = (props: Props) => {
	const { application, children } = props;
	return (
		<div className={styles.appContainer}>
			<DiRow noGutters className={styles.homeContainerRow}>
				<SideNav application={application} />
				<div className={styles.childrenWrapper}>{children}</div>
			</DiRow>
		</div>
	);
};

export default HomeContainer;
