// @flow

import { Enum } from "@deepintent/react-component-lib-core";

const FeatureAuthorisationFlag = Object.freeze({
	PUBLISH_RX: new Enum("PublishRx", "isPublishRxAuthorized"),
	DASHBOARD: new Enum("Dashboard", "isDashboardAuthorized"),
	INVENTORY: new Enum("Inventory", "isInventoryAuthorized"),
	AD_TAG: new Enum("AdTag", "isAdTagAuthorized"),
	AUDIENCE: new Enum("Audience", "isAudienceAuthorized"),
	DIRECT_AUCTION: new Enum("DirectAuction", "isDirectAuctionAuthorized"),
	DEALS: new Enum("Deals", "isDealsAuthorized"),
	CREATIVE_LIBRARY: new Enum("CreativeLibrary", "isCreativeLibraryAuthorized"),
});

export default FeatureAuthorisationFlag;
