// @flow

import React from "react";
import { ApolloLink, from, split } from "apollo-link";
import { onError } from "apollo-link-error";
import { InMemoryCache } from "apollo-cache-inmemory";
import { withClientState } from "apollo-link-state";
import { createUploadLink } from "apollo-upload-client";
import { ApolloProvider, ApolloClient } from "@apollo/client";
import {
	DiApolloClient,
	DiApolloProvider,
	DiGraphqlUploadLink,
	diAuthLink,
	diUploadFetch,
} from "@deepintent/react-apollo-utils";
import { LoginUtils, clientDetailsDefaultResolver, clientDetailsResolver } from "@deepintent/react-component-internal";
import { getApolloClientV2DefaultConfig, getApolloLinks } from "./apolloClientLinksV2";
import ConfigManager from "../utils/manager/ConfigManager";
import Constants from "../utils/Constants";

// This variable stored localXversionId in UI
let localXVersionId = null;

type Props = {
	children: React.Node,
};

new ConfigManager().init();
const graphQlApiUrl = ConfigManager.getContext().urls.graphGlApi;
const graphQlWebSocketUrl = ConfigManager.getContext().urls.graphGlWebSocket;
const httpLinkV1 = new DiGraphqlUploadLink({ uri: graphQlApiUrl });
const httpLinkV2 = createUploadLink({ uri: graphQlApiUrl, fetch: diUploadFetch });

const loginUtils = new LoginUtils(ConfigManager.getContext());
const { applicationId } = ConfigManager.getContext();

const authLink = diAuthLink({
	getHeaders: ({ variables }) => {
		const xVersionId = localXVersionId;
		const token = loginUtils.getToken();
		const orgId = loginUtils.getActiveOrganisationId() || null;
		const resourceId = loginUtils.getActiveResourceId() || null;

		return {
			Authorization: token ? `Bearer ${token}` : null,
			"X-OrganizationId": orgId,
			"X-ResourceId": resourceId,
			"X-ApplicationId": applicationId,
			/*	Add "file-upload-v2" header if file upload request
			is v2 (identified by adding fileV2 in variables) which
			helps differentiate in gateway for correct middleware usage.
		*/
			...("fileV2" in variables ? { "file-upload-v2": true } : {}),
			...(xVersionId ? { "X-versionid": xVersionId } : {}),
		};
	},
});

const errorHandler = onError(({ graphQLErrors, networkError, operation, response }) => {
	if (graphQLErrors) {
		graphQLErrors.map(({ message, locations, path, extensions }) => {
			if (extensions && extensions.code && extensions.code === Constants.LOGIN.CODES.UNAUTHENTICATED) {
				loginUtils.redirectToLogin();
			}
			if (extensions && extensions.code === Constants.GATEWAY_VERSION_MISMATCHED) {
				// Dispatching Custom Event for Graceful Api Handling
				const event = new CustomEvent(Constants.GRACEFUL_API_EVENT);
				document.dispatchEvent(event);

				return;
			}
			console.error(
				`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`
			);
		});
	}
	if (networkError) {
		console.error(`[Network error]: ${networkError}`);
		if (networkError.statusCode === 401) {
			loginUtils.logout();
		}
	}

	if (operation.operationName === "IgnoreErrorsQuery") {
		response.errors = null;
	}
});

const cache = new InMemoryCache();
const stateLink = withClientState({
	cache,
	resolvers: clientDetailsResolver,
	defaults: clientDetailsDefaultResolver,
});

// Choose correct ApolloLink link for file upload based on fileV2 variable
const httpLink = split(
	({ variables }) => {
		return "fileV2" in variables;
	},
	httpLinkV2,
	httpLinkV1
);

const afterwareLink = new ApolloLink((operation, forward) => {
	return forward(operation).map(response => {
		const context = operation.getContext();
		const headers = context?.response?.headers;

		if (headers) {
			const xVersionId = headers.get("x-versionid");
			if (!localXVersionId || localXVersionId !== xVersionId) {
				localXVersionId = xVersionId;
			}
		}

		return response;
	});
});

const diClient = new DiApolloClient({
	cache,
	link: from([authLink, afterwareLink, errorHandler, stateLink, httpLink]),
});

const setLocalXVersionId = (versionIdToSet: string) => {
	localXVersionId = versionIdToSet;
};

const params = {
	loginUtils,
	localXVersionId,
	applicationId,
	setLocalXVersionId,
	graphQlWebSocketUrl,
	graphQlApiUrl,
};

export const createApolloClient = () => {
	const apolloConfig = getApolloClientV2DefaultConfig();
	apolloConfig.link = getApolloLinks(params);
	return new ApolloClient(apolloConfig);
};

const clientV2 = createApolloClient();

export default function DiNetworkAdapter({ children }: Props) {
	if (!loginUtils.isUserTokenPresent()) {
		// redirect to login page
		loginUtils.redirectToLogin();
	}

	return (
		<DiApolloProvider client={diClient.getClient()}>
			<ApolloProvider client={clientV2}>{loginUtils.isUserTokenPresent() ? children : <div />}</ApolloProvider>
		</DiApolloProvider>
	);
}
