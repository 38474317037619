// @flow

import React from "react";
import {
	DiAdTagIcon,
	DiAudiencesIcon,
	DiCreativesIcon,
	DiDashboardIcon,
	DiDealsIcon,
	DiIMRIcon,
	DiInventoryIcon,
	DiNewSettingsIcon,
	DiOpenAuctionIcon,
} from "@deepintent/react-component-lib-core";
import FeatureAuthorisationFlag from "../enums/FeatureAuthorisationFlag";

const {
	PUBLISH_RX,
	DASHBOARD,
	INVENTORY,
	AD_TAG,
	AUDIENCE,
	DIRECT_AUCTION,
	DEALS,
	CREATIVE_LIBRARY,
} = FeatureAuthorisationFlag;

export const SIDE_NAV_CONFIG = [
	{
		id: "integration",
		menuItems: [
			{
				id: "dashboard",
				label: "Dashboard",
				iconComponent: <DiDashboardIcon />,
				authKey: DASHBOARD.value,
			},
			{
				id: "outcomes",
				label: "Outcomes",
				iconComponent: <DiIMRIcon />,
				authKey: PUBLISH_RX.value,
			},
			{
				id: "publishRx",
				label: "Settings",
				iconComponent: <DiNewSettingsIcon />,
				authKey: PUBLISH_RX.value,
			},
			{
				id: "inventory",
				label: "Inventory",
				iconComponent: <DiInventoryIcon />,
				authKey: INVENTORY.value,
			},
			{
				id: "adtag",
				label: "Ad Tag",
				iconComponent: <DiAdTagIcon className="adTagIcon" />,
				authKey: AD_TAG.value,
			},
			{
				id: "audience",
				label: "Audience",
				iconComponent: <DiAudiencesIcon />,
				authKey: AUDIENCE.value,
			},
		],
	},
	{
		id: "marketplace",
		menuItems: [
			{
				id: "directAuction",
				label: "Direct Auction",
				iconComponent: <DiOpenAuctionIcon />,
				authKey: DIRECT_AUCTION.value,
			},
			{
				id: "deals",
				label: "Deals",
				iconComponent: <DiDealsIcon />,
				authKey: DEALS.value,
			},
			{
				id: "creativeLibrary",
				label: "Creative Library",
				iconComponent: <DiCreativesIcon />,
				authKey: CREATIVE_LIBRARY.value,
			},
		],
	},
];
const isFeatureAuthorised = (featureAuthorisationFlag: string, authorisationConfig: Object) => {
	return !!authorisationConfig?.[featureAuthorisationFlag];
};

export const getMenuSections = (authorisationConfig: MenuFeaturesAuthorisationConfig): SideNavConfigType => {
	const menuSections = SIDE_NAV_CONFIG.map(sideNavItem => {
		const menuItems = sideNavItem.menuItems.filter(menuItem => {
			return isFeatureAuthorised(menuItem.authKey, authorisationConfig);
		});
		return {
			id: sideNavItem.id,
			menuItems,
		};
	});

	return menuSections;
};

// This util calculates which is the first allowed route for the user
export const getFirstAuthorizedRoute = ({ menuFeaturesAuthorisationConfig }: Object): ?string => {
	for (let idx1 = 0; idx1 < SIDE_NAV_CONFIG.length; idx1 += 1) {
		const { menuItems } = SIDE_NAV_CONFIG[idx1];
		for (let idx2 = 0; idx2 < menuItems.length; idx2 += 1) {
			if (menuFeaturesAuthorisationConfig?.[menuItems[idx2].authKey]) {
				return menuItems[idx2].id;
			}
		}
	}
	return null;
};
