// @flow

import SideNav from "./SideNav";

const AppConfig = {
	componentACLRule: {
		components: {
			...SideNav,
		},
	},
};

export default AppConfig;
