// @flow

import * as React from "react";
import { DiCard, DiOverlay, DiTypography } from "@deepintent/react-component-lib-core";

type Props = {
	children: React.Node,
	messageElm?: ?React.Node,
	messageIcon: ?React.Node,
	messageText: string,
	subtext: string,
};

type MessageProps = {
	messageText: string,
	subtext: string,
};

const Message = ({ messageText, subtext }: MessageProps) => (
	<React.Fragment>
		<DiTypography
			style={{
				fontSize: "28px",
				fontWeight: 600,
			}}
		>
			{messageText}
		</DiTypography>
		<DiTypography
			style={{
				fontSize: "16px",
			}}
		>
			{subtext}
		</DiTypography>
	</React.Fragment>
);

const overlayStyles = {
	width: "50%",
	height: "auto",
	margin: "auto",
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	textAlign: "center",
};

export default class OverlayMessage extends React.PureComponent<Props> {
	render() {
		return (
			<React.Fragment>
				<DiOverlay show style={{ zIndex: 90 }}>
					<DiCard elevated style={overlayStyles}>
						{this.props.messageIcon ? this.props.messageIcon : null}
						{this.props.messageElm && React.isValidElement(this.props.messageElm) ? (
							this.props.messageElm
						) : (
							<Message messageText={this.props.messageText} subtext={this.props.subtext} />
						)}
					</DiCard>
				</DiOverlay>
				{this.props.children}
			</React.Fragment>
		);
	}
}
