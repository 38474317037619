// @flow

import * as React from "react";
import { EnhancedCachedClientDetailsQuery } from "@deepintent/react-component-internal";
import NotificationManager from "./NotificationManager/NotificationManager";
import FullScreenLoader from "../components/shared/FullscreenLoader/FullscreenLoader";
import type { ClientDetails } from "../types";

type Props = {
	children: React.Element<any>,
};

export default class MainContainer extends React.Component<Props> {
	render() {
		const { children } = this.props;
		return (
			<div style={{ height: "100vh" }}>
				<EnhancedCachedClientDetailsQuery>
					{(clientDetails: ?ClientDetails) => {
						if (
							clientDetails &&
							Number(clientDetails.activeOrganizationId) > -1 &&
							Number(clientDetails.activeResourceId) > -1
						) {
							return <React.Fragment>{children}</React.Fragment>;
						}

						return <FullScreenLoader />;
					}}
				</EnhancedCachedClientDetailsQuery>
			</div>
		);
	}
}
