// @flow

import React from "react";
import type { UserACLRule } from "@deepintent/react-component-internal";
import type { MenuFeaturesAuthorisationConfig } from "../../types";

export type AppContextData = {
	userACLRuleLoading: boolean,
	currentUserDetails?: ?Object,
	menuFeaturesAuthorisationConfig: MenuFeaturesAuthorisationConfig,
	userACLRule: UserACLRule,
	featuresAccessLoading: boolean,
};

export const DEFAULT_MENU_FEATURES_AUTHORISATION_CONFIG = {
	isPublishRxAuthorized: true,
};

const AppContext = React.createContext<AppContextData>({
	userACLRuleLoading: true,
	currentUserDetails: null,
	menuFeaturesAuthorisationConfig: DEFAULT_MENU_FEATURES_AUTHORISATION_CONFIG,
	userACLRule: null,
	featuresAccessLoading: true,
});

export default AppContext;
