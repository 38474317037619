// @flow

import React, { useContext } from "react";
import type { Node } from "react";
import { Redirect } from "react-router-dom";
import { getFirstAuthorizedRoute } from "./utils/menuSectionUtils";
import AppContext from "./containers/HomeContainer/AppContext";
import Constant from "./utils/Constants";

const { ID_TO_PATH_MAPPING } = Constant;

const defaultRoute = ID_TO_PATH_MAPPING.dashboard;

// Redirects user to the first authorized route so user doesn't face not-authorized error
const redirectToFirstAuthorized = (): Node => {
	const { menuFeaturesAuthorisationConfig, userACLRuleLoading } = useContext(AppContext);

	if (userACLRuleLoading) {
		return null;
	}

	const firstAuthorizedRoute = getFirstAuthorizedRoute({
		menuFeaturesAuthorisationConfig,
	});

	const route = menuFeaturesAuthorisationConfig.isDashboardAuthorized ? defaultRoute : firstAuthorizedRoute;

	return <Redirect exact from="/" to={route} />;
};

export default redirectToFirstAuthorized;
