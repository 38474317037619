// @flow

import Enum from "../utils/Enum";

const SortOrder = {
	ASC: new Enum("ASC", "asc"),
	DESC: new Enum("DESC", "desc"),
};

export default SortOrder;
